import firebase from 'firebase'
import 'firebase/firestore'


const config = {
    apiKey: "AIzaSyCJW6WxqsC3NSpMoqBUfcKs2vGriUvjCro",
    authDomain: "armadillomd.firebaseapp.com",
    databaseURL: "https://armadillomd.firebaseio.com",
    projectId: "armadillomd",
    storageBucket: "armadillomd.appspot.com",
    messagingSenderId: "882070122503",
    appId: "1:882070122503:web:76fef88fbd3966e3983aa2",
    measurementId: "G-T0LG91MPRK"
}

firebase.initializeApp(config);
const analytics = firebase.analytics();
const db = firebase.firestore()
const auth = firebase.auth()
const storage=firebase.storage()
const currentUser = auth.currentUser

const productsCollection = db.collection('products');
const splintsCollection = db.collection('splints');
const conditionsCollection = db.collection('conditions');
const painPointsCollection = db.collection('painpoints');
const contactsCollection = db.collection('contacts');
const faqsCollection = db.collection('faqs');

export {
    analytics,
    db,
    auth,
    currentUser,
    storage,
    productsCollection,
    splintsCollection,
    faqsCollection,
    contactsCollection,
    conditionsCollection,
    painPointsCollection
}
