
import Vue from 'vue'
import App from './App.vue'
import './main.css'

import { firestorePlugin } from 'vuefire'
import router from './router'
Vue.use(firestorePlugin)
Vue.component("red-button", require("./components/Button.vue").default);

Vue.config.productionTip = false

const fb = require('./firebase.js')

// new Vue({
//     router,
//     render: h => h(App)
// }).$mount('#app')
let app;

// let localuser = localStorage.getItem('user');
// if (localuser != 'myhandhurts') {
//     let pass = window.prompt("Enter Access Code");
//     localStorage.setItem('user',pass);
//     location.reload();
// }
// console.log("User",localuser);

fb.auth.onAuthStateChanged(user => {
    window.user=user;
    if (!app) {
        app = new Vue({
            router,
            render: h => h(App)
        }).$mount('#app')
    }
})
