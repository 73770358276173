<template>
  <div class="text-white bg-dark lg:flex lg:flex-col">
    <div class="container px-4 pt-5 mx-auto">
      <div class="flex flex-col-reverse lg:flex-row">
        <!-- Left Side -->
        <div class="lg:w-1/2 xl:w-3/8 lg:mr-2 lg:mb-4 xl:mb-8 lg:ml-3">
          <div class="hidden lg:block">
            <img
              class="mt-4 mb-4 xl:mt-24"
              src="../assets/ArmadilloTextLogo_Desktop.svg"
              alt="ARMADILLO MD"
            />
            <h1 class="mb-4 italic text-sub">
              Taking the pain out of finding the right splint.
            </h1>
            <h2
              class="mb-4 font-extrabold leading-tight  lg:mb-0 text-mhead lg:text-head lg:hidden"
            >
              Tell us where it hurts.
            </h2>
          </div>
          <!-- <div class="lg:hidden"></div> -->
          <div class="mb-4">
            <div
              class="flex py-4 mb-4 mr-2 border-b border-white  lg:border-none lg:py-0"
            >
              <svg
                width="64px"
                height="34px"
                viewBox="0 0 23 34"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  id="Website"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="Mobile---1.0---Homepage"
                    transform="translate(-19.000000, -630.000000)"
                    fill="#FF0000"
                  >
                    <g
                      id="Group-9"
                      transform="translate(19.000000, 630.000000)"
                    >
                      <polygon
                        id="Fill-1"
                        points="11.4999075 0 7.94624123 7.24177515 0 8.40314455 5.74995377 14.0402029 4.39257491 22 11.4999075 18.2419611 18.6072402 22 17.2498613 14.0402029 23 8.40314455 15.0535739 7.24177515"
                      />
                      <g
                        id="Group-8"
                        transform="translate(5.000000, 22.000000)"
                      >
                        <path
                          d="M7.07079211,12 C6.66387105,12 6.33395,11.6972958 6.33395,11.3239437 L6.33395,0.840338028 C6.33395,0.466985915 6.66387105,0.16428169 7.07079211,0.16428169 C7.47771316,0.16428169 7.80763421,0.466985915 7.80763421,0.840338028 L7.80763421,11.3239437 C7.80763421,11.6972958 7.47771316,12 7.07079211,12"
                          id="Fill-2"
                        />
                        <path
                          d="M0.880231579,8.95494085 C0.7966,8.95494085 0.711494737,8.94175775 0.628415789,8.91403944 C0.245994737,8.7864338 0.0487052632,8.39854648 0.187968421,8.04767324 L2.41654737,2.4264338 C2.55544211,2.07539155 2.97838947,1.89437746 3.36081053,2.02215211 C3.74323158,2.14975775 3.94052105,2.53764507 3.80125789,2.88851831 L1.57267895,8.50975775 C1.46381053,8.78440563 1.18123158,8.95494085 0.880231579,8.95494085"
                          id="Fill-4"
                        />
                        <path
                          d="M13.2612789,8.95494085 C12.9602789,8.95494085 12.6777,8.78440563 12.5688316,8.50975775 L10.3402526,2.88851831 C10.2009895,2.53764507 10.3982789,2.14975775 10.7807,2.02215211 C11.1631211,1.89454648 11.5858842,2.07556056 11.7249632,2.4264338 L13.9535421,8.04767324 C14.0928053,8.39854648 13.8955158,8.7864338 13.5130947,8.91403944 C13.4300158,8.94175775 13.3449105,8.95494085 13.2612789,8.95494085"
                          id="Fill-6"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              <p class="ml-4 text-lg font-bold leading-tight text-primary">
                The only expert-curated website featuring unbiased splint
                reviews and recommendations.
              </p>
            </div>
            <h2
              class="hidden mt-24 mb-8 font-extrabold leading-tight  text-mhead lg:text-head lg:block"
            >
              Tell us where it hurts.
            </h2>

            <p>
              You can search recommendations based on your hand or wrist
              condition including carpal tunnel syndrome or Gamer's thumb, or
              search by splint type for varying types of hand, finger splints,
              or wrist braces using the navigation tabs above.
            </p>
          </div>

          <p>
            Use this interactive model to find the most dependable hand and
            wrist splint based on common sites for pain and discomfort. Choose
            the arm you are experiencing pain or discomfort in (left or right).
            Click on the site of your pain to find expert splint
            recommendations.
          </p>

          <!-- <p>
              Maybe you’ve seen a physician and received a diagnosis and/or a
              splint recommendation. If so, you can go right to our reviews and
              <router-link class="font-medium underline" to="/findby-condition"
                >search by condition</router-link
              >
              or
              <router-link
                class="font-medium underline"
                to="/findby-splint-type"
                >search by type of splint</router-link
              >.
            </p> -->
          <!-- <div>
            You can also use the interactive model
            <span class="lg:hidden">above</span>
            <span class="hidden lg:inline">on the right</span>. Click on the
            site of your pain or discomfort to find splint recommendations. We
            have included regions of commonly located wrist and hand pain.


          </div> -->
          <div class="hidden lg:block">
            <div class="mt-4 mb-2 font-bold">Select limb:</div>
            <div class>
              <div
                class="inline-block w-4 h-4 mr-2 border cursor-pointer"
                @click="left = true"
              >
                <img v-if="left" src="../assets/CheckMarkIcon.svg" />
              </div>
              Left
              <div
                class="inline-block w-4 h-4 ml-4 mr-2 border cursor-pointer"
                @click="left = false"
              >
                <img v-if="!left" src="../assets/CheckMarkIcon.svg" />
              </div>
              Right
            </div>
          </div>
        </div>
        <!-- Right Side -->
        <div class="lg:w-1/2 xl:w-5/8 lg:mr-20 lg:ml-6">
          <div class="lg:hidden">
            <div class="mb-3 italic text-center text-sub">
              Taking the pain out of finding the right splint.
            </div>
            <h1 class="mb-3 text-2xl text-center">Tell us where it hurts.</h1>
          </div>
          <hand-pain :left="left"></hand-pain>
          <div class="relative py-3 pl-3 mb-2 -mx-3 lg:hidden bg-light">
            <div class="inline mr-5 font-bold">Select limb:</div>
            <div
              class="inline-block w-4 h-4 mr-2 border cursor-pointer"
              @click="left = true"
            >
              <img v-if="left" src="../assets/CheckMarkIcon.svg" />
            </div>
            <div class="inline-block mr-3">Left</div>
            <div
              class="inline-block w-4 h-4 ml-4 mr-2 border cursor-pointer"
              @click="left = false"
            >
              <img v-if="!left" src="../assets/CheckMarkIcon.svg" />
            </div>
            <div class="inline-block">Right</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Call Out -->
    <callout />

    <div class="lg:flex-1"></div>
    <footer-md mode="dark" class="bg-dark" />
  </div>
</template>


<script>
import Footer from "../components/Footer.vue";
import HandPain from "../components/HandPain.vue";
import Callout from "../components/Callout.vue";
export default {
  components: {
    "hand-pain": HandPain,
    callout: Callout,
    "footer-md": Footer,
  },
  data() {
    return {
      left: true,
    };
  },
};
</script>
