<template>
  <div class="flex flex-col h-full">
    <div id="nav" class="text-black bg-white shadow">
      <!-- Mobile -->
      <div class="z-50 w-full pt-3 pb-2 bg-white lg:hidden" :class="isOpen ? 'fixed':''">
        <social-links type="mobile" :show="mobileVisible" v-if="!user" />
        <div class="flex justify-between mr-2 lg:hidden">
          <router-link to="/" style="border:none !important">
            <img src="./assets/ArmadilloLogo_NavBar_Mobile.svg" class="inline-block h-8 mx-4" />
          </router-link>
          <div
            class="hamburger hamburger--spring"
            :class="isOpen ? 'is-active':''"
            @click="isOpen=!isOpen"
          >
            <div class="hamburger-box">
              <div class="hamburger-inner"></div>
            </div>
          </div>
        </div>
        <div v-if="isOpen" class="shadow-md mobile-menu z-90">
          <router-link
            to="/"
            class="flex justify-between py-4 pl-4 pr-6 font-bold focus:outline-none active:font-bold"
          >
            Browse by pain location
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="20"
              height="20"
            >
              <polygon fill="#756F6E" points="20,10 0,20 0,0" />
            </svg>
          </router-link>
          <router-link
            to="/findby-condition"
            class="flex justify-between py-4 pl-4 pr-6 font-bold bg-white focus:outline-none hover:font-bold"
          >
            Browse by condition
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="20"
              height="20"
            >
              <polygon fill="#756F6E" points="20,10 0,20 0,0" />
            </svg>
          </router-link>
          <router-link
            to="/findby-splint-type"
            class="flex justify-between py-4 pl-4 pr-6 font-bold focus:outline-none"
          >
            Browse by splint type
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="20"
              height="20"
            >
              <polygon fill="#756F6E" points="20,10 0,20 0,0" />
            </svg>
          </router-link>

          <router-link
            to="/who-we-are"
            class="flex justify-between py-4 pl-4 pr-6 font-bold bg-white focus:outline-none"
          >
            Who we are
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="20" height="20">
              <polygon fill="#756F6E" points="20,10 0,20 0,0" />
            </svg>
          </router-link>
          <form class="flex h-3 mt-6 mb-8 ml-4 mr-5 bg-white" @submit.prevent="doSearch">
            <input
              type="text"
              v-model="searchTerm"
              placeholder="i.e. Carpal Tunnel"
              class="flex-1 p-4 focus:outline-none mobile-search"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 36 36"
              class="inline w-8 h-8 cursor-pointer mobile-mag"
              @click="doSearch"
            >
              <path
                d="M14.81778,28.35366a13.29855,13.29855,0,0,1,0-26.59573,13.29855,13.29855,0,0,1,0,26.59573Zm0-23.15318a9.85583,9.85583,0,1,0,9.75554,9.85588A9.81676,9.81676,0,0,0,14.81778,5.20048Z"
                transform="translate(-1.65454 -1.75793)"
              />
              <path
                class="cls-1"
                d="M33.67882,37.75793a3.94143,3.94143,0,0,1-2.81091-1.17666L22.71417,28.3447a4.04445,4.04445,0,0,1-.00055-5.67932,3.94648,3.94648,0,0,1,5.62237,0L36.48973,30.902a4.04444,4.04444,0,0,1,.00055,5.67931A3.9436,3.9436,0,0,1,33.67882,37.75793Z"
                transform="translate(-1.65454 -1.75793)"
              />
            </svg>
          </form>

          <router-link
            to="/who-we-are"
            class="block px-3 py-2 mx-2 mt-5 text-sm font-normal focus:outline-none"
          >About</router-link>
          <router-link
            to="/terms"
            class="block px-3 py-2 mx-2 text-sm font-normal focus:outline-none"
          >Terms</router-link>
          <router-link
            to="/privacy"
            class="block px-3 py-2 mx-2 text-sm font-normal focus:outline-none"
          >Privacy policy</router-link>
          <router-link
            to="/contact"
            class="block px-3 py-2 mx-2 text-sm font-normal focus:outline-none"
          >Contact</router-link>
          <router-link
            to="/faq"
            class="block px-3 py-2 mx-2 text-sm font-normal focus:outline-none"
          >FAQ</router-link>
          <p class="p-4 text-sm">
            NOTICE: This site does not provide healthcare advice is not intended to help diagnose or treat any medical condition. Please see our full disclaimer
            <router-link to="/terms" class="font-bold" style="text-decoration:underline">here</router-link>.
          </p>
        </div>
      </div>
      <!-- Desktop -->
      <div class="fixed left-0 right-0 z-50 hidden pt-0 pb-2 bg-white shadow lg:block desktop">
        <social-links type="desktop" v-if="!user" />
        <div class="container relative mx-auto mt-4">
          <div class="flex justify-between">
            <router-link to="/" style="border:none !important">
              <img
                src="./assets/ArmadilloLogo_NavBar_Desktop.svg"
                class="inline-block h-8 ml-4 mr-8 -mt-1"
              />
            </router-link>
            <div class>
              <router-link
                to="/"
                class="px-3 pt-2 mx-2 text-sm font-bold opacity-50 focus:outline-none hover:opacity-100"
              >Browse by pain location</router-link>
              <span>|</span>
              <router-link
                to="/findby-condition"
                class="px-3 pt-2 mx-2 text-sm font-bold opacity-50 focus:outline-none hover:opacity-100"
              >Browse by condition</router-link>
              <span>|</span>
              <router-link
                to="/findby-splint-type"
                class="px-3 pt-2 mx-2 text-sm font-bold opacity-50 focus:outline-none hover:opacity-100"
              >Browse by splint type</router-link>
              <span>|</span>
              <router-link
                to="/who-we-are"
                class="px-3 pt-2 mx-2 text-sm font-bold opacity-50 focus:outline-none hover:opacity-100"
              >Who we are</router-link>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 36 36"
                class="inline w-6 h-6 mx-4 cursor-pointer mag"
                @click="search"
              >
                <path
                  d="M14.81778,28.35366a13.29855,13.29855,0,0,1,0-26.59573,13.29855,13.29855,0,0,1,0,26.59573Zm0-23.15318a9.85583,9.85583,0,1,0,9.75554,9.85588A9.81676,9.81676,0,0,0,14.81778,5.20048Z"
                  transform="translate(-1.65454 -1.75793)"
                />
                <path
                  class="cls-1"
                  d="M33.67882,37.75793a3.94143,3.94143,0,0,1-2.81091-1.17666L22.71417,28.3447a4.04445,4.04445,0,0,1-.00055-5.67932,3.94648,3.94648,0,0,1,5.62237,0L36.48973,30.902a4.04444,4.04444,0,0,1,.00055,5.67931A3.9436,3.9436,0,0,1,33.67882,37.75793Z"
                  transform="translate(-1.65454 -1.75793)"
                />
              </svg>
            </div>

            <div v-if="searchActive" id="search" class="absolute bg-light search-box">
              <form @submit.prevent="doSearch" class="flex flex-row items-center justify-between">
                <input
                  type="text"
                  v-model="searchTerm"
                  id="searchbox"
                  class="flex-1 m-4 text-white bg-light focus:outline-none"
                  placeholder="i.e. Carpal Tunnel"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="16"
                  height="16"
                  class="mr-4 cursor-pointer"
                  @click="doSearch"
                >
                  <polygon fill="#fff" points="16,8 0,16 0,0" />
                </svg>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-view v-if="showRoutes" class="flex-1 lg:mt-10" />
    <div class="fixed inset-0 z-50 overflow-y-auto" v-if="notyet" @click="notyet=false">
      <div
        class="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0"
      >
        <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
        -->
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
        <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
        <div
          class="inline-block w-full max-w-sm overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:align-middle lg:max-w-xl"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div v-if="demo">
            <div class="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-start">
                <div
                  class="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10"
                >
                  <svg
                    class="w-6 h-6 text-red-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                </div>
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3
                    class="text-lg font-medium leading-6 text-gray-900"
                    id="modal-headline"
                  >Deactivate account</h3>
                  <div class="mt-2">
                    <p
                      class="text-sm leading-5 text-gray-500"
                    >Are you sure you want to deactivate your account? All of your data will be permanently removed. This action cannot be undone.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
              <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                <button
                  type="button"
                  class="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red sm:text-sm sm:leading-5"
                >Deactivate</button>
              </span>
              <span class="flex w-full mt-3 rounded-md shadow-sm sm:mt-0 sm:w-auto">
                <button
                  type="button"
                  class="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                >Cancel</button>
              </span>
            </div>
          </div>
          <div class="px-4 pt-5 pb-4 text-lg font-bold text-center bg-white sm:p-6 sm:pb-4">
            <div class="flex justify-end ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                class="cursor-pointer"
                viewBox="0 0 24 24"
              >
                <path
                  d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"
                />
              </svg>
            </div>
            <div class="items-center justify-center py-4 lg:flex lg:flex-row-reverse">
            <img src="./assets/Research_Armadillo.svg" class="mx-auto" />
            <div v-html="modalBody" class="mt-6 ml-4 lg:-mt-4"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SocialLinks from "./components/SocialLinks.vue";
import {
  conditionsCollection,
  productsCollection,
  painPointsCollection,
  splintsCollection,
  faqsCollection,
} from "./firebase";

export default {
  name: "Main",
  components: {
    "social-links": SocialLinks,
  },
  data: () => ({
    isOpen: false,
    searchActive: false,
    mobileVisible: false,
    searchTerm: "",
    shareApi: !!navigator.share,
    cms: {},
    dataReady: false,
    modalBody:
      "Evaluation of the best<br>splints for this pain point<br>is being conducted.<br> Stay tuned!",
    notyet: false,
    demo: false,
  }),
  watch: {
    $route() {
      this.isOpen = false;
    },
  },
  computed: {
    user() {
      return window.user;
    },
    showRoutes() {
      return (
        window.location.pathname == "/" ||
        window.location.pathname == "/who-we-are" ||
        window.location.pathname == "/terms" ||
        window.location.pathname == "/privacy" ||
        window.location.pathname == "/contact" ||
        this.dataReady
      );
    },
  },
  methods: {
    alert(str) {
      this.modalBody = str;
      this.notyet = true;
    },
    slug(str) {
      if (!str) return null;
      str = str.replace(/^\s+|\s+$/g, ""); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      var from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
      var to = "aaaaaaeeeeiiiioooouuuunc------";

      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }

      str = str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-") // collapse dashes
        .replace(/^-+/, "") // trim - from start of text
        .replace(/-+$/, ""); // trim - from end of text

      return str;
    },
    doSearch() {
      this.searchActive = false;
      this.isOpen = false;
      var params = { from: "search", detail: this.searchTerm };
      this.searchTerm = "";
      window.router.push({ name: "Results", params: params });
      window.scrollTo(0, 0);
    },
    search() {
      this.searchActive = !this.searchActive;
      if (this.searchActive) {
        this.$nextTick(() => {
          document.getElementById("searchbox").focus();
        });
        // this.$refs.seachbox.focus();
      }
    },
    loadData() {
      const asyncFunction = async () => {
        var snap;

        snap = await conditionsCollection.orderBy("Order").get();
        this.cms.conditions = snap.docs.map((doc) => doc.data());

        snap = await splintsCollection.orderBy("Order").get();
        this.cms.splints = snap.docs.map((doc) => doc.data());

        snap = await painPointsCollection.get();
        this.cms.painpoints = snap.docs.map((doc) => doc.data());

        snap = await faqsCollection.orderBy("order").get();
        this.cms.faqs = snap.docs.map((doc) => doc.data());

        snap = await productsCollection.get();
        const collection = snap.docs.map((doc) => doc.data());
        let cms = {};
        collection.forEach((doc) => {
          doc.slug = this.slug(doc.IDX);
          cms[doc.slug] = doc;
        });
        this.cms.products = cms;
      };
      return asyncFunction;
    },
  },
  mounted() {
    //   this.loadData();
    var collection, cms;
    conditionsCollection
      .orderBy("Order")
      .get()
      .then((snap) => {
        this.cms.conditions = snap.docs.map((doc) => doc.data());

        splintsCollection
          .orderBy("Order")
          .get()
          .then((snap) => {
            this.cms.splints = snap.docs.map((doc) => doc.data());

            productsCollection.get().then((snap) => {
              collection = snap.docs.map((doc) => doc.data());
              cms = {};
              collection.forEach((doc) => {
                doc.slug = this.slug(doc.IDX);
                cms[doc.slug] = doc;
              });
              this.cms.products = cms;

              painPointsCollection.get().then((snap) => {
                this.cms.painpoints = snap.docs.map((doc) => doc.data());

                faqsCollection
                  .orderBy("order")
                  .get()
                  .then((snap) => {
                    this.cms.faqs = snap.docs.map((doc) => doc.data());
                    this.dataReady = true;
                  });
              });
            });
          });
      });
  },
  created() {
    //   console.log(process.env.NODE_ENV);
    const handleEscape = (e) => {
      if (e.key === "Esc" || e.key === "Escape") {
        this.isOpen = false;
        this.searchActive = false;
        this.notyet = false;
      }
    };
    document.addEventListener("keydown", handleEscape);

    const handleScroll = () => {
      this.mobileVisible = document.documentElement.scrollTop > 52;
    };
    document.addEventListener("scroll", handleScroll);

    this.$once("hook:beforeDestroy", () => {
      document.removeEventListener("keydown", handleEscape);
      document.removeEventListener("scroll", handleScroll);
    });
  },
};
</script>

<style lang="scss">
#nav .desktop {
  a.router-link-exact-active {
    border-bottom: 3px solid red;
    opacity: 1;
    font-weight: 700;
  }
}

// .mobile-menu {
//   background-color: #e7e7e7;
//   position: absolute;
//   left: 0;
//   top: 50px;
//   width: 100%;
//   z-index: 1000;
// }

.mag {
  fill: #7f7a79;
  width: 36px;
  height: 36px;
}

.search-box {
  ::placeholder {
    color: #ccc;
    opacity: 1;
  }
}
.mobile-mag {
  background: #7f7a79;
  fill: white;
  padding: 4px;
  height: 36px;
  width: 36px;
}

.mag:hover {
  fill: #f00;
}

.desktop span {
  top: 11px;
}

#search {
  top: 36px;
  right: 0;
  width: 300px;
  z-index: 1000;
}

.mobile-menu {
  background-color: #e7e7e7;
  position: absolute;
  left: 0;
  top: 50px;
  width: 100%;
  z-index: 1000;
}

.mobile-search {
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  border-top: 1px solid #aaa;
  border-left: 1px solid #aaa;
  border-radius: 0;
}
</style>
