<template>
  <footer
    class="pt-2 text-xs text-center pin-b"
    :class="mode=='light' ? 'text-gray-800 light':'text-gray-100 dark' "
  >
    <div class="container mx-auto">
      <div class="flex flex-col items-center justify-center lg:flex-row align-center width-full">
        <router-link
          to="/who-we-are"
          class="px-3 py-2 mx-2 text-sm font-normal focus:outline-none"
          :class="mode=='light' ? 'text-gray-800 active:text-black' : 'text-gray-100 active:text-white'"
        >About</router-link>
        <span class="hidden lg:block">|</span>
        <router-link
          to="/terms"
          class="px-3 py-2 mx-2 text-sm font-normal focus:outline-none"
          :class="mode=='light' ? 'text-gray-800 active:text-black' : 'text-gray-100 active:text-white'"
        >Terms</router-link>
        <span class="hidden lg:block">|</span>
        <router-link
          to="/privacy"
          class="px-3 py-2 mx-2 text-sm font-normal focus:outline-none"
          :class="mode=='light' ? 'text-gray-800 active:text-black' : 'text-gray-100 active:text-white'"
        >Privacy policy</router-link>
        <span class="hidden lg:block">|</span>
        <router-link
          to="/contact"
          class="px-3 py-2 mx-2 text-sm font-normal focus:outline-none"
          :class="mode=='light' ? 'text-gray-800 active:text-black' : 'text-gray-100 active:text-white'"
        >Contact</router-link>
        <span class="hidden lg:block">|</span>
        <router-link
          to="/faq"
          class="px-3 py-2 mx-2 text-sm font-normal focus:outline-none"
          :class="mode=='light' ? 'text-gray-800 active:text-black' : 'text-gray-100 active:text-white'"
        >FAQ</router-link>
      </div>
      <p class="mt-4 mb-16 lg:mb-4 lg:mt-0">
        NOTICE: This site does not provide healthcare advice and is not intended to help diagnose or treat any medical condition.
        <span v-if="this.$router.currentRoute.name != 'Terms'">Please see our full disclaimer <router-link to="/terms" class="underline">here</router-link>.</span>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "footer-md",
  props: {
    mode: {
      //   type: string,
      default: "light",
    },
  },
//   mounted () {
//       console.log(this.$router.currentRoute.name);
//   },
};
</script>

<style >
.dark a.router-link-exact-active {
  color: white;
  font-weight: bold;
}
.light a.router-link-exact-active {
  color: black;
  font-weight: bold;
}
</style>
