<template>
  <div>
    <router-link
      v-if="type && type=='router'"
      :to="to"
      class="btn btn-primary inline pointer-events-auto nowrap"
    >
      <slot></slot>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="16"
        height="16"
        class="ml-2 inline-block"
      >
        <polygon fill="#fff" points="16,8 0,16 0,0" />
      </svg>
    </router-link>
    <button
      v-if="type && type!='router'"
      :type="type"
      class="w-30 btn btn-primary inline-flex pointer-events-auto nowrap cursor-pointer"
      @click="$emit('click')"
    >
      <slot></slot>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="16"
        height="16"
        class="ml-2 mt-1"
      >
        <polygon fill="#fff" points="16,8 0,16 0,0" />
      </svg>
    </button>
    <a
      class="w-30 btn btn-primary inline-flex pointer-events-auto nowrap cursor-pointer"
      :target="href!='#' ? '_blank':''"
      :href="href"
      @click="$emit('click')"
      v-if="!type"
    >
      <slot></slot>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="16"
        height="16"
        class="ml-2 mt-1"
      >
        <polygon fill="#fff" points="16,8 0,16 0,0" />
      </svg>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      default: "#",
    },
    type: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: '/',
    },
  },
  data() {
    return {
      blcass:
        "w-30 btn btn-primary inline-flex pointer-events-auto nowrap cursor-pointer",
    };
  },
  //   computed: {
  //       bclass() {
  //           return "w-30 btn btn-primary inline-flex pointer-events-auto nowrap cursor-pointer"
  //       }
  //   },
};
</script>

<style>
</style>