import Vue from 'vue'
import VueRouter from 'vue-router'
import FindPain from '../views/FindPain.vue'

Vue.use(VueRouter)

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.

  const routes = [
  {
    path: '/',
    name: 'FindPain',
    component: FindPain
  },
//   {
//     path: '/about',
//     name: 'About',
//     component: () => import('../views/About.vue')
//   },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('../views/Terms.vue')
  },
  {
    path: '/faq',
    name: 'Faq',
    component: () => import('../views/Faq.vue')
  },
  {
    path: '/evaluation/:from/:detail',
    name: 'Results',
    // props: {ready: false},
    component: () => import('../views/Results.vue')
  },
  {
    path: '/detail/:idx',
    name: 'Detail',
    component: () => import('../views/Detail.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/findby-condition',
    name: 'FindCondition',
    component: () => import('../views/FindCondition.vue')
  },
  {
    path: '/findby-splint-type',
    name: 'FindSplint',
    component: () => import('../views/FindSplint.vue')
  },
  {
    path: '/who-we-are',
    name: 'Who',
    component: () => import('../views/Who.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin.vue')
  },
  {
    path: '/experiment',
    name: 'Experiment',
    component: () => import('../views/Experiment.vue')
  }
];


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

window.router = router;

export default router
