<template>
  <div>
    <div v-if="type=='mobile'">
      <div v-if="shareApi">
        <svg
          id="sharebutton"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 45 45"
          @click.prevent="share"
        >
          <rect class="cls-1" width="45" height="45" rx="10" transform="translate(45) rotate(90)" />
          <polyline class="cls-2" points="29.894 13.578 15.282 22.078 29.894 30.578" />
          <circle class="cls-3" cx="29.78176" cy="13.57787" r="5" />
          <circle class="cls-3" cx="29.78176" cy="30.57787" r="5" />
          <circle class="cls-3" cx="12.78176" cy="22.57787" r="5" />
        </svg>
      </div>
      <div id="sharemobile" :class="show ? '':'social-hide'" v-else>
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 320 64"
          style="enable-background:new 0 0 320 64;max-height:70px"
          xml:space="preserve"
        >
          <path
            class="st0 cursor-pointer"
            d="M240,64.5v-45h70c5.52,0,10,4.48,10,10v35H240z"
            @click="social('link')"
          />
          <rect
            x="97.5"
            y="2"
            transform="matrix(0 1 -1 0 162 -78)"
            class="st1 cursor-pointer"
            width="45"
            height="80"
            @click="social('tw')"
          />
          <path
            class="st2 cursor-pointer"
            d="M0,64.5v-35c0-5.52,4.48-10,10-10h70v45H0z"
            @click="social('fb')"
          />
          <rect
            x="177.5"
            y="2"
            transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 242 -158)"
            class="st3 cursor-pointer"
            width="45"
            height="81"
            @click="social('email')"
          />
          <path
            class="st4 pointer-events-none"
            d="M115.03,53.6c10.85,0,16.78-8.99,16.78-16.78c0-0.26-0.01-0.51-0.02-0.76c1.15-0.83,2.15-1.87,2.94-3.05
	c-1.06,0.47-2.19,0.79-3.39,0.93c1.22-0.73,2.15-1.89,2.59-3.26c-1.14,0.68-2.4,1.17-3.75,1.43c-1.08-1.15-2.61-1.86-4.31-1.86
	c-3.26,0-5.9,2.64-5.9,5.9c0,0.46,0.05,0.91,0.15,1.35c-4.9-0.25-9.25-2.59-12.16-6.16c-0.51,0.87-0.8,1.88-0.8,2.96
	c0,2.05,1.04,3.85,2.63,4.91c-0.97-0.03-1.88-0.3-2.67-0.74c0,0.02,0,0.05,0,0.08c0,2.86,2.03,5.24,4.73,5.78
	c-0.5,0.13-1.02,0.21-1.56,0.21c-0.38,0-0.75-0.04-1.11-0.11c0.75,2.34,2.93,4.05,5.51,4.1c-2.02,1.58-4.56,2.53-7.33,2.53
	c-0.48,0-0.95-0.03-1.41-0.08C108.6,52.62,111.7,53.6,115.03,53.6"
          />
          <path
            id="XMLID_1_"
            class="st5 pointer-events-none"
            d="M36.52,49.51c0-2.2-0.01-4.39,0.01-6.59c0-0.37-0.1-0.49-0.47-0.47c-0.68,0.03-1.37,0-2.06,0.01
	c-0.28,0.01-0.38-0.07-0.38-0.36c0.01-1.37,0.01-2.74,0-4.11c0-0.26,0.08-0.35,0.34-0.34c0.7,0.02,1.4-0.02,2.1,0.02
	c0.41,0.02,0.49-0.12,0.48-0.5c-0.02-1.04-0.02-2.09,0-3.13c0.03-1.27,0.37-2.46,1.13-3.5c0.96-1.31,2.33-1.91,3.9-1.98
	c1.62-0.07,3.24-0.03,4.86-0.05c0.23,0,0.29,0.08,0.29,0.3c-0.01,1.39-0.01,2.77,0,4.16c0,0.25-0.08,0.32-0.32,0.32
	c-0.78-0.01-1.56-0.01-2.34,0c-1.04,0-1.66,0.54-1.72,1.56c-0.05,0.82-0.01,1.65-0.03,2.48c-0.01,0.29,0.17,0.27,0.36,0.27
	c1.18,0,2.37,0.01,3.55-0.01c0.35-0.01,0.46,0.07,0.42,0.44c-0.15,1.38-0.28,2.76-0.39,4.14c-0.03,0.31-0.17,0.37-0.45,0.37
	c-0.93-0.01-1.87-0.01-2.8,0c-0.67,0-0.61-0.1-0.61,0.59c0,4.3-0.01,8.6,0.01,12.9c0,0.45-0.11,0.57-0.56,0.56
	c-1.6-0.03-3.21-0.03-4.81,0c-0.45,0.01-0.53-0.14-0.52-0.55C36.53,53.84,36.52,51.67,36.52,49.51z"
          />
          <path
            class="st4 pointer-events-none"
            d="M210.67,31.6h-22.09c-1.45,0-2.63,1.18-2.63,2.63V49.6c0,1.45,1.18,2.63,2.63,2.63h22.09
	c1.45,0,2.63-1.18,2.63-2.63V34.22C213.3,32.77,212.12,31.6,210.67,31.6z M210.73,48.25c0.5,0.53,0.48,1.36-0.05,1.86
	c-0.25,0.24-0.58,0.36-0.9,0.36c-0.35,0-0.7-0.14-0.95-0.41l-5.92-6.25l-2.35,2.34c-0.26,0.26-0.59,0.38-0.93,0.38
	s-0.67-0.13-0.93-0.38l-2.35-2.34l-5.92,6.25c-0.26,0.27-0.61,0.41-0.95,0.41c-0.32,0-0.65-0.12-0.9-0.36
	c-0.53-0.5-0.55-1.33-0.05-1.86l5.97-6.3L188.52,36c-0.51-0.51-0.51-1.34,0-1.86c0.51-0.51,1.34-0.52,1.86,0l6.8,6.77
	c0.02,0.02,0.05,0.02,0.06,0.04c0.02,0.02,0.03,0.06,0.06,0.08l2.33,2.33l2.34-2.33c0.02-0.02,0.03-0.06,0.06-0.08
	c0.02-0.02,0.04-0.02,0.06-0.04l6.8-6.78c0.51-0.51,1.34-0.51,1.86,0c0.51,0.51,0.51,1.34,0,1.86l-5.97,5.95L210.73,48.25z"
          />
          <g>
            <polyline class="st6 pointer-events-none" points="287.11,33.5 272.5,42 287.11,50.5 	" />
            <circle class="st5 pointer-events-none" cx="287" cy="33.5" r="5" />
            <circle class="st5 pointer-events-none" cx="287" cy="50.5" r="5" />
            <circle class="st5 pointer-events-none" cx="270" cy="42.5" r="5" />
          </g>
        </svg>
      </div>
    </div>
    <div v-else class="fixed">
      <div v-if="shareApi">
        <svg
          id="sharebutton"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 45 45"
          @click.prevent="share"
        >
          <rect class="cls-1" width="45" height="45" rx="10" transform="translate(45) rotate(90)" />
          <polyline class="cls-2" points="29.894 13.578 15.282 22.078 29.894 30.578" />
          <circle class="cls-3" cx="29.78176" cy="13.57787" r="5" />
          <circle class="cls-3" cx="29.78176" cy="30.57787" r="5" />
          <circle class="cls-3" cx="12.78176" cy="22.57787" r="5" />
        </svg>
      </div>
      <div id="socialdesktop" v-else>
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 64.15566 255"
        >
          <path
            class="cls-1 cursor-pointer"
            @click="social('fb')"
            d="M10.15566,0h54a0,0,0,0,1,0,0V64a0,0,0,0,1,0,0h-64a0,0,0,0,1,0,0V10A10,10,0,0,1,10.15566,0Z"
          />
          <rect
            class="cls-2 cursor-pointer"
            x="0.15566"
            y="64"
            width="64"
            height="64"
            @click="social('tw')"
          />
          <path
            class="cls-3 cursor-pointer"
            @click="social('link')"
            d="M.15566,191h64a0,0,0,0,1,0,0v64a0,0,0,0,1,0,0h-54a10,10,0,0,1-10-10V191A0,0,0,0,1,.15566,191Z"
          />
          <path
            class="cls-4 pointer-events-none"
            d="M27.29,109.674c12.39776,0,19.17756-10.27116,19.17756-19.17754q0-.43782-.01927-.87139a13.714,13.714,0,0,0,3.36249-3.48989,13.45455,13.45455,0,0,1-3.87078,1.061,6.76348,6.76348,0,0,0,2.96385-3.7285,13.50723,13.50723,0,0,1-4.27979,1.636,6.74618,6.74618,0,0,0-11.48639,6.147,19.13571,19.13571,0,0,1-13.893-7.04212,6.74543,6.74543,0,0,0,2.08654,8.99817,6.6907,6.6907,0,0,1-3.05275-.84316c-.00149.02813-.00149.05635-.00149.08592a6.74315,6.74315,0,0,0,5.40754,6.608,6.72948,6.72948,0,0,1-3.04387.11551,6.7478,6.7478,0,0,0,6.29668,4.68148,13.52519,13.52519,0,0,1-8.37137,2.88524,13.72067,13.72067,0,0,1-1.60788-.09334A19.082,19.082,0,0,0,27.29,109.674"
            transform="translate(0 -0.32547)"
          />
          <path
            class="cls-5 pointer-events-none"
            d="M27.14033,41.34412c0-2.71547-.01047-5.43093.00974-8.146.00374-.45775-.12569-.60095-.58356-.58221-.84607.03429-1.69454-.00362-2.54183.016-.343.0087-.4694-.08639-.46565-.44976q.02562-2.54119.00178-5.08386c-.00313-.32593.09512-.43314.42462-.42584.86633.02,1.73439-.02426,2.59907.01861.50114.02457.605-.15351.59448-.61861q-.04539-1.93408-.002-3.87033a7.42418,7.42418,0,0,1,1.3953-4.326,6.015,6.015,0,0,1,4.82209-2.45c1.99982-.08882,4.00473-.03953,6.00694-.06109.2792-.00318.36245.09661.36124.368q-.01344,2.57036.00121,5.1415c.0027.30481-.09678.39811-.396.39332-.96306-.01495-1.92613-.0076-2.88858-.00507A1.907,1.907,0,0,0,34.35,23.19478c-.06438,1.01714-.01734,2.04038-.041,3.06052-.0087.36336.20729.3385.4478.33819,1.46332-.00225,2.92722.01453,4.39056-.01047.42953-.00749.57122.09135.52209.54353-.1842,1.70266-.34237,3.40862-.48827,5.11548-.03322.38522-.20907.45856-.55505.45287-1.15448-.01782-2.31013-.0123-3.46554-.00495-.82977.00495-.74917-.12157-.74917.72469-.00391,5.315-.01107,10.6302.0117,15.94518.00239.56136-.14107.70066-.69647.69079q-2.97463-.05485-5.95032.00092c-.55884.01107-.65083-.17856-.64644-.677C27.15111,46.69772,27.14033,44.02119,27.14033,41.34412Z"
            transform="translate(0 -0.32547)"
          />
          <path
            class="cls-4 pointer-events-none"
            d="M29.46031,227.61706a1.55988,1.55988,0,0,1-1.10646-.45848,7.8218,7.8218,0,0,1,0-11.06453l7.74518-7.74671a7.82489,7.82489,0,0,1,11.06606,11.06607l-4.42581,4.42581a1.56476,1.56476,0,1,1-2.21291-2.21291l4.42581-4.42581a4.69536,4.69536,0,0,0-6.64024-6.64025l-7.74518,7.7467a4.69309,4.69309,0,0,0,0,6.63872,1.56494,1.56494,0,0,1-1.10645,2.67139Z"
            transform="translate(0 -0.32547)"
          />
          <path
            class="cls-4 pointer-events-none"
            d="M22.82006,240.51244a7.82083,7.82083,0,0,1-5.53227-13.35386l4.42582-4.42581a1.56476,1.56476,0,0,1,2.2129,2.2129l-4.42581,4.42582a4.69537,4.69537,0,1,0,6.64025,6.64025l7.74517-7.74671a4.69306,4.69306,0,0,0,0-6.63872,1.56476,1.56476,0,0,1,2.21291-2.2129,7.8218,7.8218,0,0,1,0,11.06453l-7.74518,7.7467A7.80034,7.80034,0,0,1,22.82006,240.51244Z"
            transform="translate(0 -0.32547)"
          />
          <rect
            class="cls-6 cursor-pointer"
            y="127.67453"
            width="64"
            height="64"
            @click="social('email')"
          />
          <path
            class="cls-4 pointer-events-none"
            d="M44.77544,147.53751H19.53588a3,3,0,0,0-3,3v17.57593a3,3,0,0,0,3,3H44.77544a3,3,0,0,0,3-3V150.53751A3,3,0,0,0,44.77544,147.53751Zm.07007,19.03308a1.50006,1.50006,0,0,1-2.17871,2.0625l-6.76471-7.144-2.68744,2.6792a1.50064,1.50064,0,0,1-2.11816,0l-2.6872-2.679-6.76495,7.1438a1.50006,1.50006,0,0,1-2.17871-2.0625l6.8183-7.2002-6.81684-6.79589a1.50019,1.50019,0,0,1,2.11817-2.125l7.7644,7.74072c.023.01928.05194.02539.074.04638.02838.02686.03894.06348.06475.09192l2.66718,2.65906,2.66888-2.66064c.02539-.02808.03565-.064.06354-.09034.02167-.02063.05029-.02661.07282-.04565l7.76507-7.74145a1.50019,1.50019,0,0,1,2.11817,2.125l-6.81659,6.79565Z"
            transform="translate(0 -0.32547)"
          />
        </svg>
      </div>
    </div>
    <div class="hidden">
      <input type="text" id="clip" v-model="clip" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["type", "show"],
  //    {
  //     type: {
  //       type: String,
  //       default: "desktop"
  //     },
  //   },
  data() {
    return {
      clip: "",
      shareApi: !!navigator.share,
    };
  },
  methods: {
    share() {
      navigator
        .share({
          title: "Armadillo MD",
          text: "Taking the pain out of finding the right splint.",
          url: window.location.href,
        })
        .then(() => {
          console.log("Data was shared successfully");
        })
        .catch((error) => {
          this.error = error.message;
        });
    },
    social(service) {
      var url = window.location.href;
      //   console.log("Social Link:", service, url);
      if (navigator.share) {
        this.share();
      } else {
        switch (service) {
          case "fb":
            this.facebookLink(url);
            break;
          case "tw":
            this.twitterLink(url, "Armadillo MD");
            break;
          case "email":
            this.emailLink(url, "Armadillo MD");
            break;
          case "link":
            this.copyLink(url);
            break;
          case "sms":
            this.smsLink(url);
            break;
        }
      }
    },
    objectToGetParams(object) {
      return (
        "?" +
        Object.keys(object)
          .filter((key) => !!object[key])
          .map((key) => `${key}=${encodeURIComponent(object[key])}`)
          .join("&")
      );
    },
    facebookLink(url) {
      window.open(
        `https://www.facebook.com/sharer/sharer.php` +
          this.objectToGetParams({
            u: url,
          }),
        "__blank"
      );
    },
    twitterLink(url, { title }) {
      window.open(
        `https://www.twitter.com/share` +
          this.objectToGetParams({
            url: url,
            text: title,
          }),
        "__blank"
      );
    },
    emailLink(url, { subject, body }) {
      window.open(
        "mailto:" +
          this.objectToGetParams({
            subject: subject,
            body: body || url,
          }),
        "__blank"
      );
    },
    copyLink(url) {
      this.clip = url;
      this.$nextTick(() => {
        var copyText = document.getElementById("clip");

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/

        /* Copy the text inside the text field */
        document.execCommand("copy");

        /* Alert the copied text */
        alert("Copied current location to clipboard: \n" + copyText.value);
      });
    },
    smsLink(url) {
      window.open("sms:?&body=" + encodeURIComponent(url), "__blank");
    },
  },
};
</script>

<style lang="scss">
#socialdesktop {
  position: fixed;
  width: 64px;
  height: 255px;
  right: 0;
  top: 140px;

  .cls-1 {
    fill: #295496;
  }
  .cls-2 {
    fill: #429cd6;
  }
  .cls-3 {
    fill: #9e9a9a;
  }
  .cls-4,
  .cls-5 {
    fill: #fff;
  }
  .cls-5 {
    fill-rule: evenodd;
  }
  .cls-6 {
    fill: #807b7a;
  }
}

#sharemobile {
  position: fixed;
  bottom: 0;
  //   left: 0;
  z-index: 100;
  width: 100%;
  transition: all 0.3s ease-in-out;

  .st0 {
    fill: #9e9a9a;
  }
  .st1 {
    fill: #429cd6;
  }
  .st2 {
    fill: #295496;
  }
  .st3 {
    fill: #807b7a;
  }
  .st4 {
    fill: #ffffff;
  }
  .st5 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #ffffff;
  }

  .st6 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: none;
    stroke: #ffffff;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
}

.social-hide {
  bottom: -75px !important;
}

#sharebutton {
  position: fixed;
  z-index: 50;
  right: 10px;
  bottom: 10px;
  width: 45px;
  opacity: 0.7;
  height: 45px;
  cursor: pointer;

  .cls-1 {
    fill: #9e9a9a;
  }

  .cls-2 {
    fill: none;
    stroke: #fff;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 3px;
    fill-rule: evenodd;
  }

  .cls-3 {
    fill: #fff;
  }
}
</style>